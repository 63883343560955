import React, {useState} from 'react';
import {format} from 'date-fns';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const TimestampConverter = () => {
  usePageView();
  const seo = useSeo();
  const [timestamp, setTimestamp] = useState('');
  const [date, setDate] = useState('');
  const [dateFormat, setDateFormat] = useState('yyyy-MM-dd HH:mm:ss');
  const [convertedDate, setConvertedDate] = useState('');
  const [convertedTimestamp, setConvertedTimestamp] = useState('');
  const [error, setError] = useState('');

  const dateFormats = [
    {value: 'yyyy-MM-dd HH:mm:ss', label: 'YYYY-MM-DD HH:mm:ss'},
    {value: 'MM/dd/yyyy HH:mm:ss', label: 'MM/DD/YYYY HH:mm:ss'},
    {value: 'dd.MM.yyyy HH:mm:ss', label: 'DD.MM.YYYY HH:mm:ss'},
    {value: 'yyyy-MM-dd', label: 'YYYY-MM-DD'},
    {value: 'MMMM do, yyyy HH:mm:ss', label: 'Month D, YYYY HH:mm:ss'},
  ];

  const convertTimestampToDate = () => {
    setError('');
    try {
      const date = new Date(parseInt(timestamp));
      if (isNaN(date.getTime())) {
        throw new Error('Invalid timestamp');
      }
      setConvertedDate(format(date, dateFormat));
    } catch (err) {
      setError('Invalid timestamp: ' + err.message);
      setConvertedDate('');
    }
  };

  const convertDateToTimestamp = () => {
    setError('');
    try {
      const timestamp = new Date(date).getTime();
      if (isNaN(timestamp)) {
        throw new Error('Invalid date');
      }
      setConvertedTimestamp(timestamp.toString());
    } catch (err) {
      setError('Invalid date: ' + err.message);
      setConvertedTimestamp('');
    }
  };


  return (
      <div className="space-y-6">
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          {seo.keywords && <meta name="keywords" content={seo.keywords} />}
        </Helmet>
        <h1 className="text-2xl font-bold mb-4">Timestamp Converter</h1>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Timestamp to Date</h2>
          <div className="flex flex-col md:flex-row gap-4">
            <input
                type="text"
                value={timestamp}
                onChange={(e) => setTimestamp(e.target.value)}
                placeholder="Enter timestamp (e.g., 1625097600000)"
                className="flex-1 p-2 border rounded"
            />
            <select
                value={dateFormat}
                onChange={(e) => setDateFormat(e.target.value)}
                className="p-2 border rounded"
            >
              {dateFormats.map((format) => (
                  <option key={format.value} value={format.value}>
                    {format.label}
                  </option>
              ))}
            </select>
            <button
                onClick={convertTimestampToDate}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
            >
              Convert to Date
            </button>
          </div>
          {convertedDate && (
              <div className="p-2 bg-green-100 border border-green-300 rounded">
                Converted Date: {convertedDate}
              </div>
          )}
        </div>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Date to Timestamp</h2>
          <div className="flex flex-col md:flex-row gap-4">
            <input
                type="text"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                placeholder="Enter date (e.g., 2021-07-01 00:00:00)"
                className="flex-1 p-2 border rounded"
            />
            <button
                onClick={convertDateToTimestamp}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
            >
              Convert to Timestamp
            </button>
          </div>
          {convertedTimestamp && (
              <div className="p-2 bg-green-100 border border-green-300 rounded">
                Converted Timestamp: {convertedTimestamp}
              </div>
          )}
        </div>

        {error && (
            <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
        )}

        <div className="mt-8 space-y-6">
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
            <h2 className="font-bold mb-2">About Timestamps and Dates</h2>
            <p>A timestamp is a sequence of characters or encoded information identifying when a certain event
              occurred, usually giving date and time of day, sometimes accurate to a small fraction of a
              second. In this converter, we use Unix timestamps, which represent the number of milliseconds
              that have elapsed since January 1, 1970 (midnight UTC/GMT).</p>
          </div>

          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
            <h2 className="font-bold mb-2">How to use this converter:</h2>
            <ol className="list-decimal list-inside">
              <li>To convert from Timestamp to Date: Enter a Unix timestamp in milliseconds, select your
                preferred date format, and click "Convert to Date".
              </li>
              <li>To convert from Date to Timestamp: Enter a date in a recognizable format (e.g., "2021-07-01
                00:00:00") and click "Convert to Timestamp".
              </li>
              <li>The converted result will appear below each section.</li>
              <li>If there's an error in your input, an error message will be displayed at the bottom.</li>
            </ol>
          </div>
        </div>
      </div>
  );
};

export default TimestampConverter;