import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import {QRCodeSVG} from 'qrcode.react';
import useSeo from '../../hook/useSeo';

const QrCodeGenerator = () => {
  usePageView();
  const seo = useSeo();
  const [text, setText] = useState('');
  const [size, setSize] = useState(128);

  const handleDownload = () => {
    const canvas = document.getElementById('qr-code');
    const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrcode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">QR Code Generator</h1>

        <div className="mb-4">
          <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter text or URL"
              className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">QR Code Size:</label>
          <input
              type="range"
              min="64"
              max="256"
              value={size}
              onChange={(e) => setSize(Number(e.target.value))}
              className="w-full"
          />
          <span>{size}x{size}</span>
        </div>

        {text && (
            <div className="mt-4">
              <QRCodeSVG
                  id="qr-code"
                  value={text}
                  size={size}
                  level="H"
                  includeMargin={true}
              />
              <button
                  onClick={handleDownload}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Download QR Code
              </button>
            </div>
        )}
      </div>
  );
};

export default QrCodeGenerator;