import React from 'react';
import useSeo from '../../hook/useSeo';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';

const ContactUs = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8 mb-16">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-green-600 text-white py-4 px-6">
            <h1 className="text-3xl font-bold">Contact Us</h1>
          </div>
          <div className="p-6">
            <p className="text-gray-600 mb-6">We value your feedback and are here to assist you with any
              questions or concerns you may have about json-parser.org. Please feel free to reach out to us
              using the following methods:</p>

            <div className="space-y-6">
              <section>
                <h2 className="text-2xl font-semibold text-green-800 mb-2">Email</h2>
                <p>General Inquiries: <a href="mailto:earn.by.this.2024@gmail.com" className="text-blue-600 hover:underline">earn.by.this.2024@gmail.com</a></p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-green-800 mb-2">Mailing Address</h2>
                <p>json-parser.org</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-green-800 mb-2">Social Media</h2>
                <p>Follow us on:</p>
                <ul className="list-disc list-inside">
                  <li>Instagram: <a href="https://www.instagram.com/earn.by.this" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">@earn.by.this</a></li>
                </ul>
              </section>

              <p className="mt-6 text-gray-600">We strive to respond to all inquiries within 24-48 hours
                during business days. Your feedback helps us improve our services and provide a better
                experience for all our users.</p>

              <p className="font-semibold">Thank you for using json-parser.org!</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ContactUs;