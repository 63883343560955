// components/tutorials/JsonBasics.js
import React from 'react';
import useSeo from '../../hook/useSeo';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';

const JsonBasics = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-3xl font-bold mb-6">JSON Basics</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What is JSON?</h2>
          <p className="mb-4">JSON (JavaScript Object Notation) is a lightweight data interchange format that is easy for humans to read and write and easy for machines to parse and generate.</p>
          <p>JSON is built on two structures:</p>
          <ul className="list-disc list-inside mb-4">
            <li>A collection of name/value pairs (realized as an object)</li>
            <li>An ordered list of values (realized as an array)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">JSON Structure</h2>
          <p className="mb-4">A JSON object is enclosed in curly braces {} and contains key-value pairs. Here's a simple example:</p>
          <pre className="bg-gray-100 p-4 rounded-md mb-4">
                    {`{
  "name": "John Doe",
  "age": 30,
  "city": "New York"
}`}
                </pre>
          <p>JSON arrays are enclosed in square brackets [] and contain a list of values. For example:</p>
          <pre className="bg-gray-100 p-4 rounded-md">
                    {`[
  "apple",
  "banana",
  "cherry"
]`}
                </pre>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">JSON Data Types</h2>
          <p>JSON supports the following data types:</p>
          <ul className="list-disc list-inside">
            <li>String: "Hello, World!"</li>
            <li>Number: 42 or 3.14</li>
            <li>Boolean: true or false</li>
            <li>Null: null</li>
            <li>Object: {JSON.stringify({"key": "value"})}</li>
            <li>Array: [1, 2, 3]</li>
          </ul>
        </section>
      </div>
  );
};

export default JsonBasics;
