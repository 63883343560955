import React from 'react';
import useSeo from '../../hook/useSeo';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';

const TermsOfService = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8 mb-16">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-yellow-600 text-white py-4 px-6">
            <h1 className="text-3xl font-bold">Terms of Service</h1>
          </div>
          <div className="p-6">
            <p className="text-gray-600 mb-4">Last updated: 2024-09-29</p>

            <div className="space-y-6">
              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">1. Acceptance of Terms</h2>
                <p>By accessing and using json-parser.org (the &quot;Site&quot;) and its services
                  (the &quot;Services&quot;), you agree to comply with and be bound by these Terms of
                  Service. If you do not agree to these terms, please do not use our Site or Services.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">2. Description of Service</h2>
                <p>json-parser.org provides online tools for parsing, formatting, and manipulating JSON and
                  other data formats. All processing is done client-side in the user&apos;s browser.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">3. Use of Service</h2>
                <p>You agree to use the Service only for purposes that are permitted by these Terms and any
                  applicable law, regulation, or generally accepted practices or guidelines in the
                  relevant jurisdictions.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">4. Intellectual Property</h2>
                <p>The content, organization, graphics, design, and other matters related to the Site are
                  protected under applicable copyrights and other proprietary laws. The copying,
                  redistribution, use or publication by you of any such matters or any part of the Site is
                  strictly prohibited.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">5. Disclaimer of Warranties</h2>
                <p>The Site and its content are provided on an &quot;as is&quot; and &quot;as
                  available&quot; basis without any warranties of any kind.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">6. Limitation of Liability</h2>
                <p>In no event shall json-parser.org be liable for any damages whatsoever resulting from the
                  use or inability to use the Site or the Service.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">7. Changes to Terms</h2>
                <p>We reserve the right to modify these Terms at any time. Please review these terms
                  periodically for changes.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">8. Governing Law</h2>
                <p>These Terms shall be governed by and construed in accordance with the laws of [Your
                  Jurisdiction], without regard to its conflict of law provisions.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-yellow-800 mb-2">Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us at:</p>
                <p>Email: <a href="mailto:earn.by.this.2024@gmail.com" className="text-blue-600 hover:underline">earn.by.this.2024@gmail.com</a></p>
                <p>json-parser.org</p>
              </section>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TermsOfService;