import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const JsonParser = () => {
  usePageView();
  const seo = useSeo();

  const [input, setInput] = useState('');
  const [parsedData, setParsedData] = useState(null);
  const [error, setError] = useState('');
  const [lastSaved, setLastSaved] = useState(null);

  useEffect(() => {
    // Load saved input from localStorage when component mounts
    const savedInput = localStorage.getItem('jsonParserInput');
    if (savedInput) {
      setInput(savedInput);
    }
  }, []);

  useEffect(() => {
    const parseJson = () => {
      try {
        if (input.trim() === '') {
          setParsedData(null);
          setError('');
          return;
        }
        const parsed = JSON.parse(input);
        setParsedData(parsed);
        setError('');
      } catch (e) {
        setError('Invalid JSON: ' + e.message);
        setParsedData(null);
      }
    };

    parseJson();

    // Auto-save input to localStorage
    const saveTimeout = setTimeout(() => {
      localStorage.setItem('jsonParserInput', input);
      setLastSaved(new Date());
    }, 1000); // Save after 1 second of inactivity

    return () => clearTimeout(saveTimeout);
  }, [input]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const clearSavedData = () => {
    localStorage.removeItem('jsonParserInput');
    setInput('');
    setLastSaved(null);
  };

  const JsonView = ({data, indent = 0}) => {
    if (Array.isArray(data)) {
      return <CollapsibleView data={data} indent={indent} type="array"/>;
    } else if (typeof data === 'object' && data !== null) {
      return <CollapsibleView data={data} indent={indent} type="object"/>;
    } else {
      return <span className="json-value">{JSON.stringify(data)}</span>;
    }
  };

  const CollapsibleView = ({data, indent, type}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const items = type === 'array' ? data : Object.entries(data);

    return (
        <div style={{marginLeft: `${indent * 20}px`}}>
        <span
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="cursor-pointer select-none"
        >
          {isCollapsed ? '▶' : '▼'}
          {type === 'array' ? '[' : '{'}
          <span className="text-gray-500 ml-1">
            {type === 'array' ? `${items.length} items` : `${items.length} properties`}
          </span>
          {isCollapsed && (type === 'array' ? ']' : '}')}
        </span>
          {!isCollapsed && (
              <div className="ml-5">
                {type === 'array'
                    ? items.map((item, index) => (
                        <div key={index}>
                          <JsonView data={item} indent={indent + 1}/>
                          {index < items.length - 1 && ','}
                        </div>
                    ))
                    : items.map(([key, value], index) => (
                        <div key={key}>
                          <span className="text-purple-600">&quot;{key}&quot;</span>:{' '}
                          <JsonView data={value} indent={indent + 1}/>
                          {index < items.length - 1 && ','}
                        </div>
                    ))}
              </div>
          )}
          {!isCollapsed && <div>{type === 'array' ? ']' : '}'}</div>}
        </div>
    );
  };

  return (
      <div className="flex flex-col min-h-screen p-4">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">JSON Parser and Formatter</h1>

        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full lg:w-1/2">
            <h2 className="text-xl font-semibold mb-2">Input JSON</h2>
            <textarea
                value={input}
                onChange={handleInputChange}
                placeholder="Enter your JSON here"
                className="w-full h-[70vh] p-2 border rounded font-mono resize-none"
            />
            <div className="mt-2 text-sm text-gray-600">
              {lastSaved ? `Last saved: ${lastSaved.toLocaleTimeString()}` : 'Not saved yet'}
              <button
                  onClick={clearSavedData}
                  className="ml-4 text-red-500 hover:text-red-700"
              >
                Clear saved data
              </button>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-xl font-semibold mb-2">Parsed Result</h2>
            <div className="w-full h-[70vh] border rounded p-4 overflow-auto bg-gray-50">
              {error ? (
                  <div className="text-red-500">{error}</div>
              ) : parsedData ? (
                  <JsonView data={parsedData}/>
              ) : (
                  <p>Enter valid JSON to see the parsed result</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4">
            <h2 className="font-bold mb-2">What is JSON?</h2>
            <p>JSON (JavaScript Object Notation) is a lightweight data interchange format. It&apos;s easy for
              humans to read and write, and easy for machines to parse and generate.</p>
          </div>

          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
            <h2 className="font-bold mb-2">How to use this parser:</h2>
            <ol className="list-decimal list-inside">
              <li>Enter your JSON data in the input field above.</li>
              <li>The parser will automatically parse your input as you type.</li>
              <li>If the JSON is valid, you&apos;ll see the parsed result on the right (or below on smaller
                screens).
              </li>
              <li>If there&apos;s an error, you&apos;ll see an error message explaining what&apos;s wrong.
              </li>
              <li>Your input is automatically saved and will be restored when you revisit this page.</li>
            </ol>
          </div>

          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
            <h2 className="font-bold mb-2">JSON Tips:</h2>
            <ul className="list-disc list-inside">
              <li>Use double quotes for strings and property names.</li>
              <li>Don&apos;t use trailing commas in objects or arrays.</li>
              <li>Boolean values are written as true or false.</li>
              <li>Null is written as null.</li>
              <li>Numbers can be integers or floating point.</li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default JsonParser;