import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
import { diffWordsWithSpace } from 'diff';

const DiffChecker = () => {
  usePageView();
  const seo = useSeo();

  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [diff1, setDiff1] = useState([]);
  const [diff2, setDiff2] = useState([]);
  const [ignoreWhitespace, setIgnoreWhitespace] = useState(false);
  const [ignoreCase, setIgnoreCase] = useState(false);
  const [replaceNewline, setReplaceNewline] = useState(false);
  const [summary, setSummary] = useState({ added: 0, deleted: 0, changed: 0 });

  const preprocessText = (text) => {
    let processedText = text;
    if (ignoreWhitespace) {
      processedText = processedText.replace(/\s+/g, ' ').trim();
    }
    if (ignoreCase) {
      processedText = processedText.toLowerCase();
    }
    if (replaceNewline) {
      processedText = processedText.replace(/\n/g, ' ');
    }
    return processedText;
  };

  const performDiff = () => {
    const processedText1 = preprocessText(text1);
    const processedText2 = preprocessText(text2);

    // ?�어 ?�위�?diff ?�행
    const differences = diffWordsWithSpace(processedText1, processedText2);

    // �??�스?�에 ?�??diff ?�보 분리
    const diff1Parts = [];
    const diff2Parts = [];

    let added = 0, deleted = 0, changed = 0;

    differences.forEach(part => {
      if (part.added) {
        diff2Parts.push({ text: part.value, type: 'added' });
        added++;
      } else if (part.removed) {
        diff1Parts.push({ text: part.value, type: 'deleted' });
        deleted++;
      } else {
        diff1Parts.push({ text: part.value, type: 'unchanged' });
        diff2Parts.push({ text: part.value, type: 'unchanged' });
        if (part.value.trim()) changed++;
      }
    });

    setDiff1(diff1Parts);
    setDiff2(diff2Parts);
    setSummary({ added, deleted, changed });
  };

  const DiffTextArea = ({ parts, readOnly = false }) => (
      <div className="relative h-64 border rounded overflow-hidden">
      <textarea
          className="w-full h-full p-2 font-mono absolute opacity-0"
          readOnly={readOnly}
      />
        <div className="w-full h-full p-2 font-mono whitespace-pre-wrap overflow-auto">
          {parts.map((part, index) => (
              <span
                  key={index}
                  className={
                    part.type === 'added'
                        ? 'bg-green-200'
                        : part.type === 'deleted'
                            ? 'bg-red-200'
                            : ''
                  }
              >
            {part.text}
          </span>
          ))}
        </div>
      </div>
  );

  const ToggleButton = ({ label, checked, onChange }) => (
      <button
          onClick={() => onChange(!checked)}
          className={`px-4 py-2 rounded transition-colors ${
              checked ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
          }`}
      >
        {label}
      </button>
  );

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Diff Checker</h1>

        {/* Summary Section */}
        <div className="mb-4 p-4 bg-gray-100 rounded">
          <h2 className="font-semibold mb-2">Summary:</h2>
          <div className="flex space-x-4 text-sm">
            <span className="text-green-600">{summary.added} added lines</span>
            <span className="text-red-600">{summary.deleted} deleted lines</span>
            <span className="text-blue-600">{summary.changed} changed lines</span>
          </div>
        </div>

        {/* Toggle Buttons */}
        <div className="mb-4 flex flex-wrap gap-2">
          <ToggleButton
              label="Ignore White Spaces"
              checked={ignoreWhitespace}
              onChange={setIgnoreWhitespace}
          />
          <ToggleButton
              label="Ignore Case"
              checked={ignoreCase}
              onChange={setIgnoreCase}
          />
          <ToggleButton
              label="Replace Newline with Space"
              checked={replaceNewline}
              onChange={setReplaceNewline}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <div className="text-sm text-gray-600 mb-1">Original Text</div>
            <textarea
                value={text1}
                onChange={(e) => setText1(e.target.value)}
                className="w-full p-2 border rounded h-64 font-mono"
                placeholder="Enter first text here"
            />
          </div>
          <div>
            <div className="text-sm text-gray-600 mb-1">Modified Text</div>
            <textarea
                value={text2}
                onChange={(e) => setText2(e.target.value)}
                className="w-full p-2 border rounded h-64 font-mono"
                placeholder="Enter second text here"
            />
          </div>
        </div>

        <button
            onClick={performDiff}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Compare Texts
        </button>

        {(diff1.length > 0 || diff2.length > 0) && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">Diff Result:</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <DiffTextArea parts={diff1} readOnly />
                <DiffTextArea parts={diff2} readOnly />
              </div>
            </div>
        )}
      </div>
  );
};

export default DiffChecker;