import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const LoremIpsumGenerator = () => {
  usePageView();
  const seo = useSeo();
  const [paragraphs, setParagraphs] = useState(1);
  const [generatedText, setGeneratedText] = useState('');

  const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

  const generateLoremIpsum = () => {
    let result = '';
    for (let i = 0; i < paragraphs; i++) {
      result += loremIpsum + '\n\n';
    }
    setGeneratedText(result.trim());
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Lorem Ipsum Generator</h1>

        <div className="mb-4">
          <label className="block mb-2">Number of paragraphs:</label>
          <input
              type="number"
              min="1"
              max="10"
              value={paragraphs}
              onChange={(e) => setParagraphs(parseInt(e.target.value))}
              className="w-full p-2 border rounded"
          />
        </div>

        <button
            onClick={generateLoremIpsum}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Generate Lorem Ipsum
        </button>

        {generatedText && (
            <div>
              <h2 className="text-xl font-semibold mb-2">Generated Lorem Ipsum:</h2>
              <textarea
                  value={generatedText}
                  readOnly
                  className="w-full p-2 border rounded h-64"
              />
            </div>
        )}
      </div>
  );
};

export default LoremIpsumGenerator;