import React, { useEffect, useState } from 'react';
import useSeo from '../../hook/useSeo';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';
import { useNavigate, useParams } from 'react-router-dom';
import articles from '../../data/articles.json'; // Import articles from JSON file

const BlogItNews = () => {
    usePageView();
    const seo = useSeo();
    const navigate = useNavigate();
    const { page } = useParams();

    const currentPage = parseInt(page) || articles.length;
    const currentArticle = articles.find(article => article.id === currentPage) || articles[0];

    // Extract headings from the article content
    const [headings, setHeadings] = useState([]);
    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(currentArticle.content, 'text/html');
        const hTags = Array.from(doc.querySelectorAll('h2')).map(h => h.textContent);
        setHeadings(hTags);
    }, [currentArticle]);

    // Track scroll position to highlight current section
    const [activeHeading, setActiveHeading] = useState('');
    useEffect(() => {
        const handleScroll = () => {
            requestAnimationFrame(() => {
                const scrollPosition = window.scrollY;
                const sections = document.querySelectorAll('article h2');
                
                if (sections.length === 0) return;

                let current = '';
                sections.forEach(section => {
                    const sectionTop = section.getBoundingClientRect().top + window.scrollY;
                    if (scrollPosition >= sectionTop - 100) {
                        current = section.textContent;
                    }
                });
                
                if (current !== activeHeading) {
                    setActiveHeading(current);
                }
            });
        };

        handleScroll(); // 초기 로드시 실행
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeHeading]);

    const PageNumbers = () => {
        const totalPages = articles.length;
        const nextArticle = currentPage < totalPages ? articles[currentPage] : null;
        const prevArticle = currentPage > 1 ? articles[currentPage - 2] : null;

        return (
            <div className="flex justify-between items-stretch gap-4 mt-8 max-w-4xl mx-auto">
                {/* 이전 글 버튼 */}
                {prevArticle && (
                    <a
                        href={`/blog/it-news/page/${currentPage - 1}`}
                        className="flex-1 flex bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-200 group"
                    >
                        <div className="w-1/3">
                            <img 
                                src={prevArticle.coverImage} 
                                alt={prevArticle.title}
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="w-2/3 p-4 flex flex-col justify-between">
                            <div>
                                <div className="text-sm text-gray-500 mb-2">이전 글</div>
                                <h3 className="font-medium text-gray-900 group-hover:text-blue-500 transition-colors duration-200 line-clamp-2">
                                    {prevArticle.title}
                                </h3>
                                <p className="text-sm text-gray-600 mt-2 line-clamp-2">
                                    {prevArticle.description}
                                </p>
                            </div>
                            <div className="text-sm text-gray-500">
                                {new Date(prevArticle.date).toLocaleDateString()}
                            </div>
                        </div>
                    </a>
                )}


                {/* 다음 글 버튼 */}
                {nextArticle && (
                    <a
                        href={`/blog/it-news/page/${currentPage + 1}`}
                        className="flex-1 flex bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-200 group"
                    >
                        <div className="w-2/3 p-4 flex flex-col justify-between">
                            <div>
                                <div className="text-sm text-gray-500 mb-2">다음 글</div>
                                <h3 className="font-medium text-gray-900 group-hover:text-blue-500 transition-colors duration-200 line-clamp-2">
                                    {nextArticle.title}
                                </h3>
                                <p className="text-sm text-gray-600 mt-2 line-clamp-2">
                                    {nextArticle.description}
                                </p>
                            </div>
                            <div className="text-sm text-gray-500">
                                {new Date(nextArticle.date).toLocaleDateString()}
                            </div>
                        </div>
                        <div className="w-1/3">
                            <img 
                                src={nextArticle.coverImage} 
                                alt={nextArticle.title}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </a>
                )}
            </div>
        );
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <Helmet>
                <title>{currentArticle.seo.title}</title>
                <meta name="description" content={currentArticle.seo.description} />
                <meta name="keywords" content={currentArticle.seo.keywords} />
                {/* Open Graph and Twitter Card tags */}
                <meta property="og:title" content={currentArticle.seo.title} />
                <meta property="og:description" content={currentArticle.seo.description} />
                <meta property="og:image" content={currentArticle.coverImage} />
                <meta property="og:type" content="article" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={currentArticle.seo.title} />
                <meta name="twitter:description" content={currentArticle.seo.description} />
                <meta name="twitter:image" content={currentArticle.coverImage} />
                <meta property="article:published_time" content={currentArticle.date} />
                <meta property="article:author" content={currentArticle.author} />
                <meta property="article:section" content={currentArticle.category} />
            </Helmet>

            <div className="flex justify-between">
                {/* Main Article Content - width increased */}
                <article className="w-3/4 max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden">
                    {/* Hero Image */}
                    <img
                        src={currentArticle.coverImage}
                        alt={currentArticle.title}
                        className="w-full h-[400px] object-cover"
                    />

                    {/* Article Header */}
                    <div className="p-8">
                        <div className="flex items-center space-x-4 text-sm text-gray-600 mb-4">
                            <span>{currentArticle.category}</span>
                            <span>•</span>
                            <span>{currentArticle.readTime}</span>
                            <span>•</span>
                            <time>
                                {new Date(currentArticle.date).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </time>
                        </div>

                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            {currentArticle.title}
                        </h1>

                        <div className="flex items-center space-x-4 mb-8">
                            <img
                                src="/logo512.png"
                                alt={currentArticle.author}
                                className="w-10 h-10 rounded-full"
                            />
                            <span className="font-medium text-gray-900">{currentArticle.author}</span>
                        </div>

                        {/* Article Content */}
                        <div
                            className="prose max-w-none"
                            dangerouslySetInnerHTML={{ __html: currentArticle.content }}
                        />
                    </div>
                </article>

                {/* Sidebar moved to right */}
                <aside className="w-1/4 pl-8 sticky top-0 h-screen overflow-y-auto">
                    <nav className="bg-white rounded-lg shadow-md p-4">
                        <h2 className="text-lg font-semibold mb-4 text-gray-800">목차</h2>
                        <ul className="space-y-2">
                            {headings.map((heading, index) => (
                                <li 
                                    key={index} 
                                    className={`
                                        cursor-pointer 
                                        transition-all 
                                        duration-200 
                                        ${activeHeading === heading 
                                            ? 'text-blue-500 font-medium translate-x-2' 
                                            : 'text-gray-600 hover:text-blue-400'
                                        }
                                    `}
                                >
                                    {heading}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </aside>
            </div>

            {/* Bottom Navigation */}
            <div className="max-w-4xl mx-auto mt-8">
                <PageNumbers />
            </div>
        </div>
    );
};

export default BlogItNews;