import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const CaseConverter = () => {
  usePageView();
  const seo = useSeo();

  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [conversion, setConversion] = useState('lowercase');

  const convertCase = () => {
    switch (conversion) {
      case 'lowercase':
        setOutput(input.toLowerCase());
        break;
      case 'uppercase':
        setOutput(input.toUpperCase());
        break;
      case 'capitalize':
        setOutput(
            input.replace(/\b\w/g, (char) => char.toUpperCase())
        );
        break;
      case 'alternating':
        setOutput(
            input.split('').map((char, index) =>
                index % 2 === 0 ? char.toLowerCase() : char.toUpperCase()
            ).join('')
        );
        break;
      case 'title':
        setOutput(
            input.replace(/\b\w+/g, (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
        );
        break;
      default:
        setOutput('Invalid conversion');
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Case Converter</h1>

        <div className="mb-4">
        <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="w-full p-2 border rounded h-40"
            placeholder="Enter your text here"
        />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Conversion:</label>
          <select
              value={conversion}
              onChange={(e) => setConversion(e.target.value)}
              className="w-full p-2 border rounded"
          >
            <option value="lowercase">Lowercase</option>
            <option value="uppercase">Uppercase</option>
            <option value="capitalize">Capitalize Words</option>
            <option value="alternating">AlTeRnAtInG cAsE</option>
            <option value="title">Title Case</option>
          </select>
        </div>

        <button
            onClick={convertCase}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Convert Case
        </button>

        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Result:</h2>
          <textarea
              value={output}
              readOnly
              className="w-full p-2 border rounded h-40 bg-gray-100"
          />
        </div>
      </div>
  );
};

export default CaseConverter;