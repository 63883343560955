import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const Pastebin = () => {
  usePageView();
  const seo = useSeo();
  const [text, setText] = useState('');
  const [expiration, setExpiration] = useState('1h');
  const [isPrivate, setIsPrivate] = useState(false);
  const [shareLink, setShareLink] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to a server
    // For this example, we'll just generate a fake share link
    const fakeId = Math.random().toString(36).substring(7);
    setShareLink(`https://yourpastebin.com/${fakeId}`);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Pastebin</h1>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
          <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="w-full p-2 border rounded h-64"
              placeholder="Enter your text here"
              required
          />
          </div>

          <div className="mb-4">
            <label className="block mb-2">Expiration:</label>
            <select
                value={expiration}
                onChange={(e) => setExpiration(e.target.value)}
                className="w-full p-2 border rounded"
            >
              <option value="1h">1 Hour</option>
              <option value="1d">1 Day</option>
              <option value="1w">1 Week</option>
              <option value="1m">1 Month</option>
              <option value="never">Never</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="flex items-center">
              <input
                  type="checkbox"
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                  className="mr-2"
              />
              Private paste
            </label>
          </div>

          <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Create Paste
          </button>
        </form>

        {shareLink && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">Share Link:</h2>
              <input
                  type="text"
                  value={shareLink}
                  readOnly
                  className="w-full p-2 border rounded bg-gray-100"
              />
            </div>
        )}
      </div>
  );
};

export default Pastebin;