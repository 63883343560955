import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const TextEditor = () => {
  usePageView();
  const seo = useSeo();
  const [text, setText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    setWordCount(newText.trim().split(/\s+/).filter(Boolean).length);
    setCharCount(newText.length);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Text Editor</h1>

        <textarea
            value={text}
            onChange={handleTextChange}
            className="w-full p-2 border rounded h-96 mb-4"
            placeholder="Start typing here..."
        />

        <div className="flex justify-between text-sm text-gray-600">
          <span>Words: {wordCount}</span>
          <span>Characters: {charCount}</span>
        </div>
      </div>
  );
};

export default TextEditor;