import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const PasswordGenerator = () => {
  usePageView();
  const seo = useSeo();
  const [length, setLength] = useState(12);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);
  const [generatedPassword, setGeneratedPassword] = useState('');

  const generatePassword = () => {
    let charset = '';
    if (includeUppercase) charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (includeLowercase) charset += 'abcdefghijklmnopqrstuvwxyz';
    if (includeNumbers) charset += '0123456789';
    if (includeSymbols) charset += '!@#$%^&*()_+~`|}{[]:;?><,./-=';

    let password = '';
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setGeneratedPassword(password);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Password Generator</h1>

        <div className="mb-4">
          <label className="block mb-2">Password Length: {length}</label>
          <input
              type="range"
              min="8"
              max="32"
              value={length}
              onChange={(e) => setLength(Number(e.target.value))}
              className="w-full"
          />
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
                type="checkbox"
                checked={includeUppercase}
                onChange={(e) => setIncludeUppercase(e.target.checked)}
                className="mr-2"
            />
            Include Uppercase Letters
          </label>
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
                type="checkbox"
                checked={includeLowercase}
                onChange={(e) => setIncludeLowercase(e.target.checked)}
                className="mr-2"
            />
            Include Lowercase Letters
          </label>
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
                type="checkbox"
                checked={includeNumbers}
                onChange={(e) => setIncludeNumbers(e.target.checked)}
                className="mr-2"
            />
            Include Numbers
          </label>
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
                type="checkbox"
                checked={includeSymbols}
                onChange={(e) => setIncludeSymbols(e.target.checked)}
                className="mr-2"
            />
            Include Symbols
          </label>
        </div>

        <button
            onClick={generatePassword}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Generate Password
        </button>

        {generatedPassword && (
            <div>
              <label className="block mb-2">Generated Password:</label>
              <input
                  type="text"
                  value={generatedPassword}
                  readOnly
                  className="w-full p-2 border rounded bg-gray-100"
              />
            </div>
        )}
      </div>
  );
};

export default PasswordGenerator;