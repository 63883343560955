import React from 'react';
import {Helmet} from 'react-helmet';
import useSeo from '../../hook/useSeo';
const IpNumberConverter = () => {
  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">IpNumberConverter</h1>

        {/* Add your component logic here */}

      </div>
  );
};

export default IpNumberConverter;
