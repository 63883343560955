import { Helmet } from "react-helmet";
import React from "react";

const seoConfig = {
  "parser/json": {
    title: "JSON Parser Online | Pretty JSON Formatter | DevToolOnline",
    description: "JSON Parser Online. Our free tool offers easy validation, formatting, and analysis. Perfect for developers seeking a quick, reliable JSON solution.",
    keywords: "best JSON parser, pretty JSON formatter, JSON validator, online JSON tool, free JSON formatter, DevToolOnline, easy JSON parsing"
  },
  "parser/yaml": {
    title: "YAML Parser Online | DevToolOnline",
    description: "YAML Parser Online. Our free tool offers easy validation, formatting, and analysis. Perfect for developers seeking a quick, reliable YAML solution.",
    keywords: "best YAML parser, pretty YAML formatter, YAML validator, online YAML tool, free YAML formatter, DevToolOnline, easy YAML parsing"
  },
  "parser/csv": {
    title: "CSV Parser Online | DevToolOnline",
    description: "CSV Parser Online. Our free tool offers easy validation, formatting, and analysis. Perfect for developers seeking a quick, reliable CSV solution.",
    keywords: "best CSV parser, pretty CSV formatter, CSV validator, online CSV tool, free CSV formatter, DevToolOnline, easy CSV parsing"
  },
  "parser/xml": {
    title: "XML Parser Online | DevToolOnline",
    description: "XML Parser Online. Our free tool offers easy validation, formatting, and analysis. Perfect for developers seeking a quick, reliable XML solution.",
    keywords: "best XML parser, pretty XML formatter, XML validator, online XML tool, free XML formatter, DevToolOnline, easy XML parsing"
  },
  "parser/url": {
    title: "URL Parser Online | DevToolOnline",
    description: "Parse and analyze URLs with our free online URL parser tool. Break down URL components and understand their structure.",
    keywords: "URL parser, URL analyzer, URL decoder, URL components, URL structure, online URL tool"
  },
  "converter/json": {
    title: "JSON Converter Online | DevToolOnline",
    description: "Convert JSON to XML, CSV, or YAML with our free online converter tool. Easy-to-use and reliable JSON conversion.",
    keywords: "JSON converter, JSON to XML, JSON to CSV, JSON to YAML, online JSON converter"
  },
  "converter/timestamp": {
    title: "Timestamp Converter | DevToolOnline",
    description: "Convert between timestamps and human-readable dates with our online timestamp converter tool.",
    keywords: "timestamp converter, unix timestamp, date converter, epoch converter"
  },
  "converter/text-hex": {
    title: "Text to Hex Converter | DevToolOnline",
    description: "Convert text to hexadecimal and vice versa with our online converter tool.",
    keywords: "text to hex, hex to text, hexadecimal converter, string to hex"
  },
  "converter/unit": {
    title: "Length Unit Converter | DevToolOnline",
    description: "Convert between different units of length easily with our online unit converter tool.",
    keywords: "unit converter, length converter, measurement converter, metric imperial converter"
  },
  "converter/base": {
    title: "Base Number Converter | DevToolOnline",
    description: "Convert numbers between different bases (binary, decimal, hexadecimal) with our online converter.",
    keywords: "base converter, number system converter, binary converter, hex converter"
  },
  "escape/html": {
    title: "HTML Escape/Unescape Tool | DevToolOnline",
    description: "Escape and unescape HTML special characters with our online tool.",
    keywords: "HTML escape, HTML unescape, HTML encoder, HTML decoder"
  },
  "escape/javascript": {
    title: "JavaScript String Escape Tool | DevToolOnline",
    description: "Escape and unescape JavaScript strings with our online tool.",
    keywords: "JavaScript escape, string escape, JS encoder, JS decoder"
  },
  "escape/json": {
    title: "JSON String Escape Tool | DevToolOnline",
    description: "Escape and unescape JSON strings with our online tool.",
    keywords: "JSON escape, JSON unescape, JSON encoder, JSON decoder"
  },
  "escape/xml": {
    title: "XML Escape/Unescape Tool | DevToolOnline",
    description: "Escape and unescape XML special characters with our online tool.",
    keywords: "XML escape, XML unescape, XML encoder, XML decoder"
  },
  "escape/sql": {
    title: "SQL String Escape Tool | DevToolOnline",
    description: "Escape and unescape SQL strings with our online tool.",
    keywords: "SQL escape, SQL unescape, SQL string encoder, SQL string decoder"
  },
  "texttools/string-utilities": {
    title: "String Utilities Online | DevToolOnline",
    description: "Powerful string manipulation tools for developers. Count characters, reverse text, and more.",
    keywords: "string utilities, text tools, string manipulation, character count"
  },
  "texttools/case-converter": {
    title: "Case Converter | DevToolOnline",
    description: "Convert text between different cases: uppercase, lowercase, title case, and more.",
    keywords: "case converter, text case, uppercase converter, lowercase converter"
  },
  "texttools/sort-lines": {
    title: "Sort Lines Online | DevToolOnline",
    description: "Sort lines alphabetically, numerically, or by length with our online tool.",
    keywords: "sort lines, text sorter, line sorter, alphabetical sort"
  },
  "texttools/diff-checker": {
    title: "Online Diff Checker & Text Compare Tool | DevToolOnline",
    description: "Compare two texts and find differences easily with our online diff checker tool.",
    keywords: "diff checker, text compare, text difference, file comparison"
  },
  "texttools/text-editor": {
    title: "Online Text Editor | DevToolOnline",
    description: "Simple and efficient online text editor with useful features for developers.",
    keywords: "text editor, online editor, code editor, plain text editor"
  },
  "texttools/json-editor": {
    title: "JSON Editor Online | DevToolOnline",
    description: "Edit and format JSON with syntax highlighting and validation.",
    keywords: "JSON editor, JSON formatter, JSON validator, code editor"
  },
  "texttools/lorem-ipsum": {
    title: "Lorem Ipsum Generator | DevToolOnline",
    description: "Generate Lorem Ipsum placeholder text for your designs and layouts.",
    keywords: "lorem ipsum, text generator, placeholder text, dummy text"
  },
  "texttools/slug-generator": {
    title: "URL Slug Generator | DevToolOnline",
    description: "Generate URL-friendly slugs from text with our online tool.",
    keywords: "slug generator, URL slugs, SEO friendly URLs, permalink generator"
  },
  "texttools/html-stripper": {
    title: "HTML Tag Stripper | DevToolOnline",
    description: "Remove HTML tags from text while preserving content.",
    keywords: "HTML stripper, remove HTML tags, clean HTML, strip tags"
  },
  "crypto/hash-generator": {
    title: "Hash Generator Online | DevToolOnline",
    description: "Generate various types of hash values (MD5, SHA1, SHA256, etc.) with our online tool.",
    keywords: "hash generator, MD5, SHA1, SHA256, hash calculator"
  },
  "crypto/hmac-generator": {
    title: "HMAC Generator | DevToolOnline",
    description: "Generate HMAC (Hash-based Message Authentication Code) with various algorithms.",
    keywords: "HMAC generator, message authentication, crypto tools, HMAC calculator"
  },
  "crypto/checksum-calculator": {
    title: "Checksum Calculator | DevToolOnline",
    description: "Calculate file and text checksums with multiple algorithms.",
    keywords: "checksum calculator, file integrity, checksum generator, hash checker"
  },
  "crypto/password-generator": {
    title: "Secure Password Generator | DevToolOnline",
    description: "Generate strong, secure passwords with customizable options.",
    keywords: "password generator, secure password, random password, strong password"
  },
  "crypto/uuid-generator": {
    title: "UUID Generator | DevToolOnline",
    description: "Generate random UUIDs (Universally Unique Identifiers) online.",
    keywords: "UUID generator, GUID generator, unique identifier, random UUID"
  },
  "imagetools/resizer-compressor": {
    title: "Image Resizer & Compressor Online | DevToolOnline",
    description: "Resize and compress images online with our free tool. Maintain quality while reducing file size.",
    keywords: "image resizer, image compressor, photo optimizer, image tools"
  },
  "imagetools/color-picker": {
    title: "Color Picker Tool | DevToolOnline",
    description: "Pick colors and get their HEX, RGB, and HSL values with our online tool.",
    keywords: "color picker, color codes, HEX color, RGB color"
  },
  "imagetools/qr-code-generator": {
    title: "QR Code Generator | DevToolOnline",
    description: "Generate QR codes for URLs, text, and more with our free online tool.",
    keywords: "QR code generator, QR maker, QR creator, barcode generator"
  },
  "imagetools/qr-code-reader": {
    title: "QR Code Reader | DevToolOnline",
    description: "Read and decode QR codes from images with our online tool.",
    keywords: "QR code reader, QR scanner, QR decoder, barcode reader"
  },
  "imagetools/exif-viewer": {
    title: "EXIF Data Viewer | DevToolOnline",
    description: "View and analyze EXIF metadata from images with our online tool.",
    keywords: "EXIF viewer, image metadata, photo information, EXIF data"
  },
  "networktools/my-ip": {
    title: "What Is My IP | DevToolOnline",
    description: "Find out your current IP address and network information.",
    keywords: "IP address checker, what is my IP, IP finder, IP lookup"
  },
  "networktools/ip-lookup": {
    title: "IP Address Lookup | DevToolOnline",
    description: "Look up information about any IP address with our online tool.",
    keywords: "IP lookup, IP information, IP geolocation, IP details"
  },
  "networktools/whois-lookup": {
    title: "WHOIS Lookup Tool | DevToolOnline",
    description: "Look up WHOIS information for domains and IP addresses.",
    keywords: "WHOIS lookup, domain information, IP WHOIS, domain lookup"
  },
  "tutorials/json-basics": {
    title: "JSON Basics Tutorial | DevToolOnline",
    description: "Learn the basics of JSON format and structure with our comprehensive guide.",
    keywords: "JSON tutorial, JSON basics, learn JSON, JSON guide"
  },
  "tutorials/json-rest-api": {
    title: "JSON in REST APIs | DevToolOnline",
    description: "Learn how to use JSON in REST APIs with practical examples.",
    keywords: "REST API, JSON API, API tutorial, web services"
  },
  "tutorials/json-faq": {
    title: "JSON FAQ | DevToolOnline",
    description: "Frequently asked questions about JSON and common solutions.",
    keywords: "JSON FAQ, JSON help, JSON questions, JSON answers"
  },
  "tools/json-templates": {
    title: "JSON Templates | DevToolOnline",
    description: "Ready-to-use JSON templates for common data structures.",
    keywords: "JSON templates, JSON examples, JSON patterns, JSON schemas"
  }
};

export default seoConfig;