import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const IpNumberConverter = () => {
  usePageView();
  const seo = useSeo();
  const [ip, setIp] = useState('');
  const [number, setNumber] = useState('');
  const [mode, setMode] = useState('ipToNumber');

  const ipToNumber = (ip) => {
    return ip.split('.').reduce((ipInt, octet) => (ipInt << 8) + parseInt(octet, 10), 0) >>> 0;
  };

  const numberToIp = (number) => {
    return ((number >> 24) & 255) + '.' +
        ((number >> 16) & 255) + '.' +
        ((number >> 8) & 255) + '.' +
        (number & 255);
  };

  const handleConvert = () => {
    if (mode === 'ipToNumber') {
      setNumber(ipToNumber(ip).toString());
    } else {
      setIp(numberToIp(parseInt(number)));
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">IP/Number Converter</h1>

        <div className="mb-4">
          <select
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              className="w-full p-2 border rounded mb-2"
          >
            <option value="ipToNumber">IP to Number</option>
            <option value="numberToIp">Number to IP</option>
          </select>

          {mode === 'ipToNumber' ? (
              <input
                  type="text"
                  value={ip}
                  onChange={(e) => setIp(e.target.value)}
                  placeholder="Enter IP address (e.g., 192.168.0.1)"
                  className="w-full p-2 border rounded"
              />
          ) : (
              <input
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Enter number (e.g., 3232235521)"
                  className="w-full p-2 border rounded"
              />
          )}
        </div>

        <button
            onClick={handleConvert}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Convert
        </button>

        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Result:</h2>
          <p className="text-lg">
            {mode === 'ipToNumber' ? number : ip}
          </p>
        </div>
      </div>
  );
};

export default IpNumberConverter;