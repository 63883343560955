import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

// Custom Switch component
const Switch = ({ id, checked, onChange }) => (
    <label htmlFor={id} className="flex items-center cursor-pointer">
      <div className="relative">
        <input
            type="checkbox"
            id={id}
            className="sr-only"
            checked={checked}
            onChange={onChange}
        />
        <div className={`block w-10 h-6 rounded-full ${checked ? 'bg-blue-600' : 'bg-gray-600'}`}></div>
        <div className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${checked ? 'transform translate-x-4' : ''}`}></div>
      </div>
    </label>
);

// Custom Label component
const Label = ({ htmlFor, children }) => (
    <label htmlFor={htmlFor} className="ml-3 text-sm font-medium text-gray-900">
      {children}
    </label>
);

const UnitConverter = () => {
  usePageView();
  const seo = useSeo();

  const [fromValue, setFromValue] = useState('');
  const [fromUnit, setFromUnit] = useState('m');
  const [toUnit, setToUnit] = useState('km');
  const [result, setResult] = useState('');
  const [isAutoConvert, setIsAutoConvert] = useState(false);

  const lengthUnits = [
    { value: 'mm', label: 'Millimeters (mm)' },
    { value: 'cm', label: 'Centimeters (cm)' },
    { value: 'm', label: 'Meters (m)' },
    { value: 'km', label: 'Kilometers (km)' },
    { value: 'in', label: 'Inches (in)' },
    { value: 'ft', label: 'Feet (ft)' },
    { value: 'yd', label: 'Yards (yd)' },
    { value: 'mi', label: 'Miles (mi)' },
  ];

  const conversionFactors = {
    mm: 0.001,
    cm: 0.01,
    m: 1,
    km: 1000,
    in: 0.0254,
    ft: 0.3048,
    yd: 0.9144,
    mi: 1609.344,
  };

  const handleConvert = () => {
    const fromValueNum = parseFloat(fromValue);
    if (isNaN(fromValueNum)) {
      setResult('Please enter a valid number');
      return;
    }

    const baseValue = fromValueNum * conversionFactors[fromUnit];
    const convertedValue = baseValue / conversionFactors[toUnit];
    setResult(`${fromValueNum} ${fromUnit} = ${convertedValue.toFixed(6)} ${toUnit}`);
  };

  const handleInputChange = (e) => {
    setFromValue(e.target.value);
    if (isAutoConvert) {
      handleConvert();
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Length Unit Converter</h1>

        <div className="mb-4">
          <input
              type="number"
              value={fromValue}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              placeholder="Enter value"
          />
        </div>

        <div className="mb-4 flex justify-between items-center">
          <select
              value={fromUnit}
              onChange={(e) => setFromUnit(e.target.value)}
              className="w-full p-2 border rounded mr-2"
          >
            {lengthUnits.map((unit) => (
                <option key={unit.value} value={unit.value}>
                  {unit.label}
                </option>
            ))}
          </select>

          <select
              value={toUnit}
              onChange={(e) => setToUnit(e.target.value)}
              className="w-full p-2 border rounded ml-2"
          >
            {lengthUnits.map((unit) => (
                <option key={unit.value} value={unit.value}>
                  {unit.label}
                </option>
            ))}
          </select>
        </div>

        <div className="flex items-center space-x-2 mb-4">
          <Switch
              id="auto-convert"
              checked={isAutoConvert}
              onChange={() => setIsAutoConvert(!isAutoConvert)}
          />
          <Label htmlFor="auto-convert">Auto Convert</Label>
        </div>

        {!isAutoConvert && (
            <button
                onClick={handleConvert}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
              Convert
            </button>
        )}

        {result && (
            <div className="mt-4 p-4 bg-gray-100 rounded">
              <h2 className="text-xl font-semibold">Result:</h2>
              <p>{result}</p>
            </div>
        )}

        <div className="mt-8 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4">
          <h2 className="font-bold mb-2">How to use this converter:</h2>
          <ol className="list-decimal list-inside">
            <li>Enter the value you want to convert in the input field.</li>
            <li>Select the unit you're converting from in the first dropdown.</li>
            <li>Select the unit you're converting to in the second dropdown.</li>
            <li>If Auto Convert is off, click the "Convert" button to see the result.</li>
            <li>If Auto Convert is on, the result will update automatically as you type.</li>
          </ol>
        </div>

        <div className="mt-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
          <h2 className="font-bold mb-2">Note:</h2>
          <p>This converter handles the following length units: millimeters (mm), centimeters (cm), meters (m), kilometers (km), inches (in), feet (ft), yards (yd), and miles (mi).</p>
        </div>
      </div>
  );
};

export default UnitConverter;