import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import {v1 as uuidv1, v4 as uuidv4} from 'uuid';
import useSeo from '../../hook/useSeo';

const UuidGenerator = () => {
  usePageView();
  const seo = useSeo();
  const [uuidVersion, setUuidVersion] = useState('v4');
  const [generatedUuid, setGeneratedUuid] = useState('');

  const generateUuid = () => {
    if (uuidVersion === 'v1') {
      setGeneratedUuid(uuidv1());
    } else {
      setGeneratedUuid(uuidv4());
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">UUID Generator</h1>

        <div className="mb-4">
          <label className="block mb-2">UUID Version:</label>
          <select
              value={uuidVersion}
              onChange={(e) => setUuidVersion(e.target.value)}
              className="w-full p-2 border rounded"
          >
            <option value="v1">Version 1 (Time-based)</option>
            <option value="v4">Version 4 (Random)</option>
          </select>
        </div>

        <button
            onClick={generateUuid}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Generate UUID
        </button>

        {generatedUuid && (
            <div>
              <label className="block mb-2">Generated UUID:</label>
              <input
                  type="text"
                  value={generatedUuid}
                  readOnly
                  className="w-full p-2 border rounded bg-gray-100"
              />
            </div>
        )}

        <div className="mt-8 bg-gray-100 p-4 rounded">
          <h2 className="text-lg font-semibold mb-2">About UUIDs</h2>
          <p>
            A UUID (Universally Unique Identifier) is a 128-bit number used to identify information in computer
            systems.
            UUIDs are standardized by the Open Software Foundation (OSF) as part of the Distributed Computing
            Environment (DCE).
          </p>
          <ul className="list-disc list-inside mt-2">
            <li>Version 1 UUIDs are generated based on the current timestamp and MAC address of the computer.
            </li>
            <li>Version 4 UUIDs are generated using random or pseudo-random numbers.</li>
          </ul>
        </div>
      </div>
  );
};

export default UuidGenerator;