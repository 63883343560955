import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const MyIp = () => {
  usePageView();
  const seo = useSeo();
  const [ip, setIp] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          setIp(data.ip);
          setLoading(false);
        })
        .catch(err => {
          setError('Failed to fetch IP address');
          setLoading(false);
        });
  }, []);

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">My IP Address</h1>

        {loading ? (
            <p>Loading your IP address...</p>
        ) : error ? (
            <p className="text-red-500">{error}</p>
        ) : (
            <div>
              <p className="text-xl">Your IP address is:</p>
              <p className="text-3xl font-bold mt-2">{ip}</p>
            </div>
        )}
      </div>
  );
};

export default MyIp;