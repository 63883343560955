import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const BaseConverter = () => {
  usePageView();
  const seo = useSeo();
  const [inputValue, setInputValue] = useState('');
  const [fromBase, setFromBase] = useState(10);
  const [toBase, setToBase] = useState(2);
  const [result, setResult] = useState('');

  const handleConvert = () => {
    try {
      const decimal = parseInt(inputValue, fromBase);
      const converted = decimal.toString(toBase);
      setResult(converted);
    } catch (error) {
      setResult('Invalid input');
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Base Converter</h1>

        <div className="mb-4">
          <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter number"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">From Base:</label>
          <select
              value={fromBase}
              onChange={(e) => setFromBase(Number(e.target.value))}
              className="w-full p-2 border rounded"
          >
            <option value={2}>Binary (2)</option>
            <option value={8}>Octal (8)</option>
            <option value={10}>Decimal (10)</option>
            <option value={16}>Hexadecimal (16)</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2">To Base:</label>
          <select
              value={toBase}
              onChange={(e) => setToBase(Number(e.target.value))}
              className="w-full p-2 border rounded"
          >
            <option value={2}>Binary (2)</option>
            <option value={8}>Octal (8)</option>
            <option value={10}>Decimal (10)</option>
            <option value={16}>Hexadecimal (16)</option>
          </select>
        </div>

        <button
            onClick={handleConvert}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Convert
        </button>

        {result && (
            <div className="mt-4 p-4 bg-gray-100 rounded">
              <h2 className="text-xl font-semibold">Result:</h2>
              <p>{result}</p>
            </div>
        )}
      </div>
  );
};

export default BaseConverter;