import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const HtmlStripper = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const stripHtml = () => {
    const stripped = input.replace(/<[^>]*>?/gm, '');
    setOutput(stripped);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">HTML Stripper</h1>

        <div className="mb-4">
        <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="w-full p-2 border rounded h-40"
            placeholder="Enter HTML text here"
        />
        </div>

        <button
            onClick={stripHtml}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Strip HTML
        </button>

        <div>
          <h2 className="text-xl font-semibold mb-2">Stripped Text:</h2>
          <textarea
              value={output}
              readOnly
              className="w-full p-2 border rounded h-40 bg-gray-100"
          />
        </div>
      </div>
  );
};

export default HtmlStripper;