// components/tutorials/JsonFaq.js
import React from 'react';
import useSeo from '../../hook/useSeo';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';

const JsonFaq = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-3xl font-bold mb-6">JSON FAQ</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. What is the difference between JSON and JavaScript objects?</h2>
          <p>While JSON is derived from JavaScript object notation, there are some key differences:</p>
          <ul className="list-disc list-inside">
            <li>JSON requires double quotes for strings and property names.</li>
            <li>JSON doesn't support functions or undefined as values.</li>
            <li>JSON is a data format, while JavaScript objects are a programming language concept.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. How do I handle nested JSON structures?</h2>
          <p>Nested JSON structures can be accessed using dot notation or bracket notation. For example:</p>
          <pre className="bg-gray-100 p-4 rounded-md">
                    {`const data = {
  "user": {
    "name": "John",
    "address": {
      "city": "New York"
    }
  }
};

console.log(data.user.address.city); // Output: "New York"
console.log(data["user"]["address"]["city"]); // Also outputs: "New York"`}
                </pre>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. How can I validate JSON?</h2>
          <p>You can validate JSON in several ways:</p>
          <ul className="list-disc list-inside">
            <li>Use online JSON validators (like the one on our site).</li>
            <li>Use the JSON.parse() method in JavaScript, which will throw an error if the JSON is invalid.</li>
            <li>Use JSON schema validation libraries for more complex validation requirements.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">4. Can JSON contain comments?</h2>
          <p>No, the official JSON specification does not allow comments. If you need to include comments, you might consider using a format like JSONC (JSON with Comments) or storing comment data within the JSON structure itself.</p>
        </section>
      </div>
  );
};

export default JsonFaq;
