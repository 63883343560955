// components/tutorials/JsonRestApi.js
import React from 'react';
import useSeo from '../../hook/useSeo';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';

const JsonRestApi = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-3xl font-bold mb-6">Building REST APIs with JSON</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">What is a REST API?</h2>
          <p className="mb-4">REST (Representational State Transfer) is an architectural style for designing networked applications. A RESTful API is an application program interface that uses HTTP requests to GET, PUT, POST and DELETE data.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Using JSON in REST APIs</h2>
          <p className="mb-4">JSON is commonly used for serializing and transmitting structured data in REST APIs. Here's an example of how a JSON payload might look in a REST API response:</p>
          <pre className="bg-gray-100 p-4 rounded-md">
                    {`{
  "id": 1,
  "name": "John Doe",
  "email": "john@example.com",
  "orders": [
    {
      "id": 101,
      "product": "Widget",
      "quantity": 2
    },
    {
      "id": 102,
      "product": "Gadget",
      "quantity": 1
    }
  ]
}`}
                </pre>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Example: Creating a Simple REST API</h2>
          <p className="mb-4">Here's a basic example of how you might set up a simple REST API endpoint using Node.js and Express:</p>
          <pre className="bg-gray-100 p-4 rounded-md">
                    {`const express = require('express');
const app = express();
app.use(express.json());

app.get('/api/users/:id', (req, res) => {
  const userId = req.params.id;
  // In a real app, you'd fetch this data from a database
  const user = {
    id: userId,
    name: "John Doe",
    email: "john@example.com"
  };
  res.json(user);
});

app.listen(3000, () => console.log('Server running on port 3000'));`}
                </pre>
        </section>
      </div>
  );
};

export default JsonRestApi;
