import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
import Papa from 'papaparse';

const CsvParser = () => {
  usePageView();
  const seo = useSeo();

  const [input, setInput] = useState('');
  const [parsedData, setParsedData] = useState(null);
  const [error, setError] = useState('');
  const [lastSaved, setLastSaved] = useState(null);

  useEffect(() => {
    const savedInput = localStorage.getItem('csvParserInput');
    if (savedInput) {
      setInput(savedInput);
    }
  }, []);

  useEffect(() => {
    const parseCsv = () => {
      try {
        if (input.trim() === '') {
          setParsedData(null);
          setError('');
          return;
        }
        const result = Papa.parse(input, {
          header: true,
          skipEmptyLines: true,
        });
        if (result.errors.length > 0) {
          setError('CSV parsing error: ' + result.errors[0].message);
          setParsedData(null);
        } else {
          setParsedData(result.data);
          setError('');
        }
      } catch (e) {
        setError('Invalid CSV: ' + e.message);
        setParsedData(null);
      }
    };

    parseCsv();

    const saveTimeout = setTimeout(() => {
      localStorage.setItem('csvParserInput', input);
      setLastSaved(new Date());
    }, 1000);

    return () => clearTimeout(saveTimeout);
  }, [input]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const clearSavedData = () => {
    localStorage.removeItem('csvParserInput');
    setInput('');
    setLastSaved(null);
  };

  const CsvTable = ({ data }) => {
    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    return (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead>
            <tr>
              {headers.map((header, index) => (
                  <th key={index} className="py-2 px-4 border-b font-semibold text-left">
                    {header}
                  </th>
              ))}
            </tr>
            </thead>
            <tbody>
            {data.map((row, rowIndex) => (
                <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-50' : ''}>
                  {headers.map((header, cellIndex) => (
                      <td key={cellIndex} className="py-2 px-4 border-b">
                        {row[header]}
                      </td>
                  ))}
                </tr>
            ))}
            </tbody>
          </table>
        </div>
    );
  };

  return (
      <div className="flex flex-col min-h-screen p-4">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">CSV Parser and Formatter</h1>

        <div className="flex flex-col lg:flex-row gap-4">
          <div className="w-full lg:w-1/2">
            <h2 className="text-xl font-semibold mb-2">Input CSV</h2>
            <textarea
                value={input}
                onChange={handleInputChange}
                placeholder="Enter your CSV here"
                className="w-full h-[70vh] p-2 border rounded font-mono resize-none"
            />
            <div className="mt-2 text-sm text-gray-600">
              {lastSaved ? `Last saved: ${lastSaved.toLocaleTimeString()}` : 'Not saved yet'}
              <button
                  onClick={clearSavedData}
                  className="ml-4 text-red-500 hover:text-red-700"
              >
                Clear saved data
              </button>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <h2 className="text-xl font-semibold mb-2">Parsed Result</h2>
            <div className="w-full h-[70vh] border rounded p-4 overflow-auto bg-gray-50">
              {error ? (
                  <div className="text-red-500">{error}</div>
              ) : parsedData ? (
                  <CsvTable data={parsedData} />
              ) : (
                  <p>Enter valid CSV to see the parsed result</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4">
            <h2 className="font-bold mb-2">What is CSV?</h2>
            <p>CSV (Comma-Separated Values) is a simple file format used to store tabular data, such as a spreadsheet or database. Each line of the file is a data record, and each record consists of fields separated by commas.</p>
          </div>

          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
            <h2 className="font-bold mb-2">How to use this parser:</h2>
            <ol className="list-decimal list-inside">
              <li>Enter your CSV data in the input field above.</li>
              <li>The parser will automatically parse your input as you type.</li>
              <li>If the CSV is valid, you'll see the parsed result on the right (or below on smaller screens).</li>
              <li>If there's an error, you'll see an error message explaining what's wrong.</li>
              <li>Your input is automatically saved and will be restored when you revisit this page.</li>
            </ol>
          </div>

          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
            <h2 className="font-bold mb-2">CSV Tips:</h2>
            <ul className="list-disc list-inside">
              <li>Each line represents a row of data.</li>
              <li>Fields are separated by commas.</li>
              <li>The first row is typically used for column headers.</li>
              <li>Use quotes around fields that contain commas or line breaks.</li>
              <li>Be consistent with your use of spaces after commas.</li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default CsvParser;