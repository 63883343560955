import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import CryptoJS from 'crypto-js';
import useSeo from '../../hook/useSeo';

const HmacGenerator = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [key, setKey] = useState('');
  const [algorithm, setAlgorithm] = useState('SHA256');
  const [hmac, setHmac] = useState('');

  const generateHmac = () => {
    let result;
    switch (algorithm) {
      case 'MD5':
        result = CryptoJS.HmacMD5(input, key);
        break;
      case 'SHA1':
        result = CryptoJS.HmacSHA1(input, key);
        break;
      case 'SHA256':
        result = CryptoJS.HmacSHA256(input, key);
        break;
      case 'SHA512':
        result = CryptoJS.HmacSHA512(input, key);
        break;
      default:
        result = CryptoJS.HmacSHA256(input, key);
    }
    setHmac(result.toString());
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">HMAC Generator</h1>

        <div className="mb-4">
          <label className="block mb-2">Input Text:</label>
          <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="w-full p-2 border rounded h-40"
              placeholder="Enter text to generate HMAC"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Secret Key:</label>
          <input
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter secret key"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">HMAC Algorithm:</label>
          <select
              value={algorithm}
              onChange={(e) => setAlgorithm(e.target.value)}
              className="w-full p-2 border rounded"
          >
            <option value="MD5">HMAC-MD5</option>
            <option value="SHA1">HMAC-SHA1</option>
            <option value="SHA256">HMAC-SHA256</option>
            <option value="SHA512">HMAC-SHA512</option>
          </select>
        </div>

        <button
            onClick={generateHmac}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Generate HMAC
        </button>

        {hmac && (
            <div>
              <label className="block mb-2">Generated HMAC:</label>
              <input
                  type="text"
                  value={hmac}
                  readOnly
                  className="w-full p-2 border rounded bg-gray-100"
              />
            </div>
        )}
      </div>
  );
};

export default HmacGenerator;