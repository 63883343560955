import React, {useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import jsQR from 'jsqr';
import useSeo from '../../hook/useSeo';

const QrCodeReader = () => {
  usePageView();
  const seo = useSeo();
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          setResult(code.data);
          setError('');
        } else {
          setError('No QR code found in the image.');
          setResult('');
        }
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">QR Code Reader</h1>

        <div className="mb-4">
          <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              ref={fileInputRef}
              className="hidden"
          />
          <button
              onClick={() => fileInputRef.current.click()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Upload QR Code Image
          </button>
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        {result && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">QR Code Content:</h2>
              <p className="bg-gray-100 p-4 rounded break-all">{result}</p>
            </div>
        )}
      </div>
  );
};

export default QrCodeReader;