import React, {useState} from 'react';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const TextHexConverter = () => {
  usePageView();
  const seo = useSeo();
  const [text, setText] = useState('');
  const [hex, setHex] = useState('');
  const [error, setError] = useState('');

  const textToHex = () => {
    setError('');
    try {
      const hexResult = text.split('')
          .map(char => char.charCodeAt(0).toString(16).padStart(2, '0'))
          .join(' ');
      setHex(hexResult);
    } catch (err) {
      setError('Error converting text to hex: ' + err.message);
    }
  };

  const hexToText = () => {
    setError('');
    try {
      const hexValues = hex.replace(/\s/g, ''); // Remove all whitespace
      if (hexValues.length % 2 !== 0) {
        throw new Error('Invalid hex string length. It should be even.');
      }
      const textResult = hexValues.match(/.{1,2}/g)
          .map(byte => String.fromCharCode(parseInt(byte, 16)))
          .join('');
      setText(textResult);
    } catch (err) {
      setError('Error converting hex to text: ' + err.message);
    }
  };

  return (
      <div className="space-y-6">
        <h1 className="text-2xl font-bold mb-4">Text / Hex Converter</h1>

        <div className="space-y-2">
          <h2 className="text-xl font-semibold">Text to Hex</h2>
          <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter text here"
              className="w-full p-2 border rounded h-24 font-mono"
          />
          <button
              onClick={textToHex}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
          >
            Convert to Hex
          </button>
        </div>

        <div className="space-y-2">
          <h2 className="text-xl font-semibold">Hex to Text</h2>
          <textarea
              value={hex}
              onChange={(e) => setHex(e.target.value)}
              placeholder="Enter hex values here (space separated)"
              className="w-full p-2 border rounded h-24 font-mono"
          />
          <button
              onClick={hexToText}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200"
          >
            Convert to Text
          </button>
        </div>

        {error && (
            <div className="p-3 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
        )}
      </div>
  );
};

export default TextHexConverter;