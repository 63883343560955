import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const JavaScriptEscapeUnescape = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('escape');

  const escapeJS = (str) => {
    return str
        .replace(/\\/g, '\\\\')
        .replace(/'/g, "\\'")
        .replace(/"/g, '\\"')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/\t/g, '\\t')
        .replace(/\b/g, '\\b')
        .replace(/\f/g, '\\f');
  };

  const unescapeJS = (str) => {
    return str
        .replace(/\\'/g, "'")
        .replace(/\\"/g, '"')
        .replace(/\\\\/g, '\\')
        .replace(/\\n/g, '\n')
        .replace(/\\r/g, '\r')
        .replace(/\\t/g, '\t')
        .replace(/\\b/g, '\b')
        .replace(/\\f/g, '\f');
  };

  const handleConvert = () => {
    if (mode === 'escape') {
      setOutput(escapeJS(input));
    } else {
      setOutput(unescapeJS(input));
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">JavaScript Escape/Unescape</h1>

        <div className="mb-4">
          <label className="block mb-2">Mode:</label>
          <select
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              className="w-full p-2 border rounded"
          >
            <option value="escape">Escape</option>
            <option value="unescape">Unescape</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2">Input:</label>
          <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="w-full p-2 border rounded h-40"
              placeholder={mode === 'escape' ? 'Enter JavaScript to escape' : 'Enter escaped JavaScript to unescape'}
          />
        </div>

        <button
            onClick={handleConvert}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          {mode === 'escape' ? 'Escape JavaScript' : 'Unescape JavaScript'}
        </button>

        <div>
          <label className="block mb-2">Output:</label>
          <textarea
              value={output}
              readOnly
              className="w-full p-2 border rounded h-40 bg-gray-100"
          />
        </div>
      </div>
  );
};

export default JavaScriptEscapeUnescape;