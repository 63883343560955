import React, {useState} from 'react';
import {json2xml} from 'xml-js';
import Papa from 'papaparse';
import yaml from 'js-yaml';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const JsonConverter = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [convertedOutput, setConvertedOutput] = useState('');
  const [outputFormat, setOutputFormat] = useState('xml');
  const [error, setError] = useState('');

  const convertJson = () => {
    try {
      const parsedData = JSON.parse(input);
      switch (outputFormat) {
        case 'xml':
          setConvertedOutput(json2xml(JSON.stringify(parsedData), {compact: true, spaces: 2}));
          break;
        case 'csv':
          if (Array.isArray(parsedData)) {
            setConvertedOutput(Papa.unparse(parsedData));
          } else if (typeof parsedData === 'object') {
            // If it's an object, convert it to an array of objects
            const array = [parsedData];
            setConvertedOutput(Papa.unparse(array));
          } else {
            throw new Error('Input must be an array or object for CSV conversion');
          }
          break;
        case 'yaml':
          setConvertedOutput(yaml.dump(parsedData));
          break;
        default:
          setConvertedOutput(JSON.stringify(parsedData, null, 2));
      }
      setError('');
    } catch (e) {
      setError('Error converting: ' + e.message);
      setConvertedOutput('');
    }
  };

  return (
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">Input JSON</h2>
          <textarea
              className="w-full h-[75vh] p-2 border rounded"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter your JSON here"
          />
          <div className="mt-4">
            <select
                className="mr-4 p-2 border rounded"
                value={outputFormat}
                onChange={(e) => setOutputFormat(e.target.value)}
            >
              <option value="xml">XML</option>
              <option value="csv">CSV</option>
              <option value="yaml">YAML</option>
            </select>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={convertJson}
            >
              Convert
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">Converted Output</h2>
          {error ? (
              <div className="text-red-500 p-2 border border-red-500 rounded">{error}</div>
          ) : (
              <textarea
                  className="w-full h-[75vh] p-2 border rounded bg-gray-100"
                  value={convertedOutput}
                  readOnly
              />
          )}
        </div>
      </div>
  );
};

export default JsonConverter;