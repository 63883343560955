import React from 'react';
import useSeo from '../../hook/useSeo';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';

const PrivacyPolicy = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8 mb-16">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-blue-600 text-white py-4 px-6">
            <h1 className="text-3xl font-bold">Privacy Policy</h1>
          </div>
          <div className="p-6">
            <p className="text-gray-600 mb-4">Last updated: 2024-09-29</p>

            <div className="space-y-6">
              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Introduction</h2>
                <p>Welcome to json-parser.org. This Privacy Policy explains how we collect, use, disclose,
                  and safeguard your information when you visit our website json-parser.org
                  (the &quot;Site&quot;) or use our online JSON parsing and related services
                  (collectively, the &quot;Services&quot;).</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Information We Collect</h2>
                <p>We do not collect any personal information from users of our Site or Services. Our tools
                  process data client-side, meaning all operations are performed in your browser. We do
                  not store or have access to any data you input or generate using our tools.</p>
              </section>

              {/* Add more sections as needed */}

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p>Email: <a href="mailto:earn.by.this.2024@gmail.com" className="text-blue-600 hover:underline">earn.by.this.2024@gmail.com</a></p>
                <p>json-parser.org</p>
              </section>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PrivacyPolicy;