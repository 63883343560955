import React from 'react';
import useSeo from '../../hook/useSeo';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';

const AboutUs = () => {
  usePageView();
  const seo = useSeo();

  return (
      <div className="container mx-auto px-4 py-8 mb-16">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-blue-600 text-white py-4 px-6">
            <h1 className="text-3xl font-bold">About json-parser.org</h1>
          </div>
          <div className="p-6">
            <div className="space-y-6">
              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Our Mission</h2>
                <p>At json-parser.org, our mission is to provide developers, data analysts, and tech
                  enthusiasts with powerful, easy-to-use online tools for working with JSON and other data
                  formats. We believe in making data manipulation and parsing accessible to everyone,
                  regardless of their technical expertise.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">What We Offer</h2>
                <p>json-parser.org offers a suite of free, browser-based tools including:</p>
                <ul className="list-disc list-inside ml-4 mt-2">
                  <li>JSON Parser and Formatter</li>
                  <li>JSON to XML Converter</li>
                  <li>JSON to CSV Converter</li>
                  <li>YAML Parser</li>
                  <li>And many more...</li>
                </ul>
                <p className="mt-2">All our tools operate client-side, ensuring your data&apos;s privacy and
                  security.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Our Story</h2>
                <p>json-parser.org was founded in [Year] by a group of developers who were frustrated with
                  the lack of simple, accessible online tools for working with JSON. What started as a
                  small project has grown into a platform used by thousands of developers worldwide.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Our Team</h2>
                <p>We are a small, dedicated team of developers and designers passionate about creating
                  useful tools for the tech community. While we prefer to remain anonymous, we&apos;re
                  always working hard behind the scenes to improve our services and add new features.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Open Source Commitment</h2>
                <p>We believe in the power of open source software. Many of the libraries and frameworks we
                  use are open source, and we strive to give back to the community whenever possible.</p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold text-blue-800 mb-2">Get Involved</h2>
                <p>We&apos;re always looking for ways to improve json-parser.org. If you have suggestions,
                  feedback, or would like to contribute, please don&apos;t hesitate to reach out to us via
                  our Contact page.</p>
              </section>

              <p className="mt-6 font-semibold">Thank you for using json-parser.org. We&apos;re excited to see
                what you&apos;ll create with our tools!</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AboutUs;
