import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const CidrIpConverter = () => {
  usePageView();
  const seo = useSeo();

  const [cidr, setCidr] = useState('');
  const [ipRange, setIpRange] = useState('');
  const [mode, setMode] = useState('cidrToRange');

  const cidrToRange = (cidr) => {
    const [ip, bits] = cidr.split('/');
    const mask = ~(2 ** (32 - bits) - 1);
    const start = ip.split('.').reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) & mask;
    const end = start + (2 ** (32 - bits) - 1);

    return {
      start: `${start >>> 24}.${(start >> 16) & 255}.${(start >> 8) & 255}.${start & 255}`,
      end: `${end >>> 24}.${(end >> 16) & 255}.${(end >> 8) & 255}.${end & 255}`,
    };
  };

  const rangeToIp = (ipRange) => {
    const [start, end] = ipRange.split('-').map(ip => ip.trim());
    const startInt = start.split('.').reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0);
    const endInt = end.split('.').reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0);
    const bits = 32 - Math.log2(endInt - startInt + 1);
    return `${start}/${bits}`;
  };

  const handleConvert = () => {
    if (mode === 'cidrToRange') {
      const range = cidrToRange(cidr);
      setIpRange(`${range.start} - ${range.end}`);
    } else {
      setCidr(rangeToIp(ipRange));
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">CIDR/IP Converter</h1>

        <div className="mb-4">
          <select
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              className="w-full p-2 border rounded mb-2"
          >
            <option value="cidrToRange">CIDR to IP Range</option>
            <option value="rangeToCidr">IP Range to CIDR</option>
          </select>

          {mode === 'cidrToRange' ? (
              <input
                  type="text"
                  value={cidr}
                  onChange={(e) => setCidr(e.target.value)}
                  placeholder="Enter CIDR (e.g., 192.168.0.0/24)"
                  className="w-full p-2 border rounded"
              />
          ) : (
              <input
                  type="text"
                  value={ipRange}
                  onChange={(e) => setIpRange(e.target.value)}
                  placeholder="Enter IP range (e.g., 192.168.0.0 - 192.168.0.255)"
                  className="w-full p-2 border rounded"
              />
          )}
        </div>

        <button
            onClick={handleConvert}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Convert
        </button>

        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Result:</h2>
          <p className="text-lg">
            {mode === 'cidrToRange' ? ipRange : cidr}
          </p>
        </div>
      </div>
  );
};

export default CidrIpConverter;