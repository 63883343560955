import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const StringUtilities = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [operation, setOperation] = useState('length');

  const performOperation = () => {
    switch (operation) {
      case 'length':
        setOutput(`Length: ${input.length}`);
        break;
      case 'reverse':
        setOutput(input.split('').reverse().join(''));
        break;
      case 'uppercase':
        setOutput(input.toUpperCase());
        break;
      case 'lowercase':
        setOutput(input.toLowerCase());
        break;
      case 'trim':
        setOutput(input.trim());
        break;
      case 'wordCount':
        setOutput(`Word count: ${input.trim().split(/\s+/).length}`);
        break;
      default:
        setOutput('Invalid operation');
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">String Utilities</h1>

        <div className="mb-4">
        <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="w-full p-2 border rounded h-40"
            placeholder="Enter your text here"
        />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Operation:</label>
          <select
              value={operation}
              onChange={(e) => setOperation(e.target.value)}
              className="w-full p-2 border rounded"
          >
            <option value="length">Calculate Length</option>
            <option value="reverse">Reverse String</option>
            <option value="uppercase">Convert to Uppercase</option>
            <option value="lowercase">Convert to Lowercase</option>
            <option value="trim">Trim Whitespace</option>
            <option value="wordCount">Count Words</option>
          </select>
        </div>

        <button
            onClick={performOperation}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Perform Operation
        </button>

        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Result:</h2>
          <textarea
              value={output}
              readOnly
              className="w-full p-2 border rounded h-40 bg-gray-100"
          />
        </div>
      </div>
  );
};

export default StringUtilities;