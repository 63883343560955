import { useLocation } from 'react-router-dom';
import seoConfig from '../seoConfig';

const useSeo = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const path = pathSegments.slice(-2).join('/');

  return seoConfig[path] || { title: 'DevToolOnline', description: 'Online developer tools' };
};

export default useSeo;