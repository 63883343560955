import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const UrlParser = () => {
  usePageView();
  const seo = useSeo();
  const [url, setUrl] = useState('');
  const [parsedUrl, setParsedUrl] = useState(null);

  const parseUrl = () => {
    try {
      const parsed = new URL(url);
      setParsedUrl({
        protocol: parsed.protocol,
        hostname: parsed.hostname,
        port: parsed.port,
        pathname: parsed.pathname,
        search: parsed.search,
        hash: parsed.hash,
        origin: parsed.origin,
        host: parsed.host,
      });
    } catch (error) {
      setParsedUrl(null);
      alert('Invalid URL. Please enter a valid URL including the protocol (e.g., http:// or https://).');
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">URL Parser</h1>

        <div className="mb-4">
          <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL (e.g., https://www.example.com/path?param=value)"
              className="w-full p-2 border rounded"
          />
        </div>

        <button
            onClick={parseUrl}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Parse URL
        </button>

        {parsedUrl && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">Parsed URL Components:</h2>
              <table className="w-full border-collapse border">
                <tbody>
                {Object.entries(parsedUrl).map(([key, value]) => (
                    <tr key={key} className="border">
                      <td className="p-2 font-semibold border">{key}</td>
                      <td className="p-2 border">{value || '(empty)'}</td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
        )}
      </div>
  );
};

export default UrlParser;