import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const SlugGenerator = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [slug, setSlug] = useState('');

  const generateSlug = () => {
    const slugified = input
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

    setSlug(slugified);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Slug Generator</h1>

        <div className="mb-4">
          <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="w-full p-2 border rounded"
              placeholder="Enter text to generate slug"
          />
        </div>

        <button
            onClick={generateSlug}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Generate Slug
        </button>

        {slug && (
            <div>
              <h2 className="text-xl font-semibold mb-2">Generated Slug:</h2>
              <input
                  type="text"
                  value={slug}
                  readOnly
                  className="w-full p-2 border rounded bg-gray-100"
              />
            </div>
        )}
      </div>
  );
};

export default SlugGenerator;