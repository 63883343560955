import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const SortLines = () => {
  usePageView();
  const seo = useSeo();
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [sortType, setSortType] = useState('alphabetical');
  const [sortOrder, setSortOrder] = useState('ascending');

  const sortLines = () => {
    let lines = input.split('\n').filter(line => line.trim() !== '');

    switch (sortType) {
      case 'alphabetical':
        lines.sort((a, b) => a.localeCompare(b));
        break;
      case 'length':
        lines.sort((a, b) => a.length - b.length);
        break;
      case 'numeric':
        lines.sort((a, b) => parseFloat(a) - parseFloat(b));
        break;
      default:
        break;
    }

    if (sortOrder === 'descending') {
      lines.reverse();
    }

    setOutput(lines.join('\n'));
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Sort Lines</h1>

        <div className="mb-4">
        <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="w-full p-2 border rounded h-40"
            placeholder="Enter your lines here, one per line"
        />
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="flex-1">
            <label className="block mb-2">Sort Type:</label>
            <select
                value={sortType}
                onChange={(e) => setSortType(e.target.value)}
                className="w-full p-2 border rounded"
            >
              <option value="alphabetical">Alphabetical</option>
              <option value="length">Line Length</option>
              <option value="numeric">Numeric</option>
            </select>
          </div>
          <div className="flex-1">
            <label className="block mb-2">Sort Order:</label>
            <select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className="w-full p-2 border rounded"
            >
              <option value="ascending">Ascending</option>
              <option value="descending">Descending</option>
            </select>
          </div>
        </div>

        <button
            onClick={sortLines}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Sort Lines
        </button>

        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Result:</h2>
          <textarea
              value={output}
              readOnly
              className="w-full p-2 border rounded h-40 bg-gray-100"
          />
        </div>
      </div>
  );
};

export default SortLines;