import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';

const ImageResizerCompressor = () => {
  usePageView();
  const seo = useSeo();

  const [selectedFile, setSelectedFile] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [maxWidth, setMaxWidth] = useState('Infinity');
  const [maxHeight, setMaxHeight] = useState('Infinity');
  const [minWidth, setMinWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);
  const [quality, setQuality] = useState(0.8);
  const [fileType, setFileType] = useState('auto');
  const [originalInfo, setOriginalInfo] = useState(null);
  const [compressedInfo, setCompressedInfo] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);

  useEffect(() => {
    const dropZone = dropZoneRef.current;
    if (!dropZone) return;

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFile(e.dataTransfer.files[0]);
      }
    };

    dropZone.addEventListener('dragover', handleDragOver);
    dropZone.addEventListener('dragleave', handleDragLeave);
    dropZone.addEventListener('drop', handleDrop);

    return () => {
      dropZone.removeEventListener('dragover', handleDragOver);
      dropZone.removeEventListener('dragleave', handleDragLeave);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleFile = (file) => {
    if (file && file.type.startsWith('image/')) {
      // ?�로???�일???�력?�면 ?�전 ?�태 초기??      setCompressedImage(null);
      setCompressedInfo(null);
      setSelectedFile(file);
      setIsUploaded(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        setOriginalImage(e.target.result);
        const img = new Image();
        img.onload = () => {
          setOriginalInfo({
            name: file.name,
            fileType: file.type,
            size: formatSize(file.size),
            dimensions: `${img.width} x ${img.height} pixels`
          });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file");
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      handleFile(event.target.files[0]);
    }
  };

  const compressImage = () => {
    if (!selectedFile) return;

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      // Apply size constraints
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
      width = Math.max(width, minWidth);
      height = Math.max(height, minHeight);

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
          (blob) => {
            const compressedUrl = URL.createObjectURL(blob);
            setCompressedImage(compressedUrl);
            setCompressedInfo({
              name: selectedFile.name,
              fileType: blob.type,
              size: formatSize(blob.size),
              dimensions: `${width} x ${height} pixels`,
              efficiency: calculateEfficiency(selectedFile.size, blob.size)
            });
          },
          fileType === 'auto' ? selectedFile.type : `image/${fileType}`,
          quality
      );
    };
    img.src = originalImage;
  };

  const formatSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const calculateEfficiency = (originalSize, compressedSize) => {
    const efficiency = ((originalSize - compressedSize) / originalSize) * 100;
    return efficiency.toFixed(2) + '% off';
  };

  const downloadCompressedImage = () => {
    if (compressedImage) {
      const link = document.createElement('a');
      link.href = compressedImage;
      link.download = 'compressed_' + selectedFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">Image Resizer & Compressor</h1>
        <p className="mb-4">With this tool, you can quickly resize and compress images, control quality, and convert PNG to JPG.</p>

        <div className="bg-gray-100 p-4 rounded-lg mb-6">
          <h2 className="text-lg font-semibold mb-2">Options</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <label className="block mb-1">Max Width</label>
              <input type="text" value={maxWidth} onChange={(e) => setMaxWidth(e.target.value)} className="w-full p-2 border rounded" placeholder="Infinity" />
            </div>
            <div>
              <label className="block mb-1">Max Height</label>
              <input type="text" value={maxHeight} onChange={(e) => setMaxHeight(e.target.value)} className="w-full p-2 border rounded" placeholder="Infinity" />
            </div>
            <div>
              <label className="block mb-1">Min Width</label>
              <input type="number" value={minWidth} onChange={(e) => setMinWidth(e.target.value)} className="w-full p-2 border rounded" placeholder="0" />
            </div>
            <div>
              <label className="block mb-1">Min Height</label>
              <input type="number" value={minHeight} onChange={(e) => setMinHeight(e.target.value)} className="w-full p-2 border rounded" placeholder="0" />
            </div>
            <div>
              <label className="block mb-1">Quality</label>
              <select value={quality} onChange={(e) => setQuality(parseFloat(e.target.value))} className="w-full p-2 border rounded">
                <option value="0.8">0.8</option>
                <option value="0.6">0.6</option>
                <option value="0.4">0.4</option>
                <option value="0.2">0.2</option>
              </select>
            </div>
            <div>
              <label className="block mb-1">File Type</label>
              <select value={fileType} onChange={(e) => setFileType(e.target.value)} className="w-full p-2 border rounded">
                <option value="auto">Auto</option>
                <option value="jpeg">JPEG</option>
                <option value="png">PNG</option>
              </select>
            </div>
          </div>
        </div>

        <div
            ref={dropZoneRef}
            className={`border-2 border-dashed ${isDragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300'} rounded-lg p-8 text-center mb-6 transition-all duration-300 ease-in-out`}
        >
          <input
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              ref={fileInputRef}
              className="hidden"
          />
          <button
              onClick={() => fileInputRef.current.click()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Select Image
          </button>
          <p className="mt-2 text-gray-600">or drag and drop your image here</p>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {originalImage && originalInfo && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Original Image</h3>
                <img src={originalImage} alt="Original" className="max-w-full h-auto mb-2" />
                <div>
                  <p><strong>Name:</strong> {originalInfo.name}</p>
                  <p><strong>File Type:</strong> {originalInfo.fileType}</p>
                  <p><strong>Size:</strong> {originalInfo.size}</p>
                  <p><strong>Dimensions:</strong> {originalInfo.dimensions}</p>
                </div>
              </div>
          )}

          {compressedImage && compressedInfo && (
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Compressed Image</h3>
                <img src={compressedImage} alt="Compressed" className="max-w-full h-auto mb-2" />
                <div>
                  <p><strong>Name:</strong> {compressedInfo.name}</p>
                  <p><strong>File Type:</strong> {compressedInfo.fileType}</p>
                  <p><strong>Size:</strong> {compressedInfo.size}</p>
                  <p><strong>Dimensions:</strong> {compressedInfo.dimensions}</p>
                  <p><strong>Efficiency:</strong> {compressedInfo.efficiency}</p>
                </div>
                <button
                    onClick={downloadCompressedImage}
                    className="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Download Compressed Image
                </button>
              </div>
          )}
        </div>

        <button
            onClick={compressImage}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={!selectedFile}
        >
          Compress Image
        </button>
      </div>
  );
};

export default ImageResizerCompressor;
