import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import useSeo from '../../hook/useSeo';
const JsonEditor = () => {
  usePageView();
  const seo = useSeo();
  const [jsonInput, setJsonInput] = useState('');
  const [formattedJson, setFormattedJson] = useState('');
  const [error, setError] = useState('');

  const formatJson = () => {
    try {
      const parsed = JSON.parse(jsonInput);
      setFormattedJson(JSON.stringify(parsed, null, 2));
      setError('');
    } catch (e) {
      setError('Invalid JSON: ' + e.message);
      setFormattedJson('');
    }
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">JSON Editor</h1>

        <div className="mb-4">
        <textarea
            value={jsonInput}
            onChange={(e) => setJsonInput(e.target.value)}
            className="w-full p-2 border rounded h-64"
            placeholder="Enter your JSON here"
        />
        </div>

        <button
            onClick={formatJson}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Format JSON
        </button>

        {error && (
            <div className="text-red-500 mb-4">{error}</div>
        )}

        {formattedJson && (
            <div>
              <h2 className="text-xl font-semibold mb-2">Formatted JSON:</h2>
              <pre className="bg-gray-100 p-4 rounded overflow-auto max-h-96">
            {formattedJson}
          </pre>
            </div>
        )}
      </div>
  );
};

export default JsonEditor;