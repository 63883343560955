import React, {useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import usePageView from '../../hook/userPageView';
import EXIF from 'exif-js';
import useSeo from '../../hook/useSeo';

const ExifViewer = () => {
  usePageView();
  const seo = useSeo();
  const [exifData, setExifData] = useState(null);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    EXIF.getData(file, function () {
      const allMetaData = EXIF.getAllTags(this);
      if (Object.keys(allMetaData).length === 0) {
        setError('No EXIF data found in the image.');
        setExifData(null);
      } else {
        setExifData(allMetaData);
        setError('');
      }
    });
  };

  const renderExifData = () => {
    if (!exifData) return null;

    return Object.entries(exifData).map(([key, value]) => (
        <tr key={key} className="border-b">
          <td className="py-2 px-4 font-semibold">{key}</td>
          <td className="py-2 px-4">{value.toString()}</td>
        </tr>
    ));
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-2xl font-bold mb-4">EXIF Viewer</h1>

        <div className="mb-4">
          <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              ref={fileInputRef}
              className="hidden"
          />
          <button
              onClick={() => fileInputRef.current.click()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Upload Image
          </button>
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        {exifData && (
            <div className="mt-4">
              <h2 className="text-xl font-semibold mb-2">EXIF Data:</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead className="bg-gray-100">
                  <tr>
                    <th className="py-2 px-4 text-left">Property</th>
                    <th className="py-2 px-4 text-left">Value</th>
                  </tr>
                  </thead>
                  <tbody>
                  {renderExifData()}
                  </tbody>
                </table>
              </div>
            </div>
        )}
      </div>
  );
};

export default ExifViewer;