// components/tools/JsonTemplates.js
import React, { useState } from 'react';
import useSeo from '../../hook/useSeo';
import { Helmet } from 'react-helmet';
import usePageView from '../../hook/userPageView';

const JsonTemplates = () => {
  usePageView();
  const seo = useSeo();

  const templates = {
    userProfile: {
      name: "User Profile",
      template: `{
  "id": 1,
  "username": "johndoe",
  "email": "john@example.com",
  "firstName": "John",
  "lastName": "Doe",
  "age": 30,
  "address": {
    "street": "123 Main St",
    "city": "Anytown",
    "state": "CA",
    "zipCode": "12345"
  },
  "phoneNumbers": [
    {
      "type": "home",
      "number": "555-1234"
    },
    {
      "type": "work",
      "number": "555-5678"
    }
  ]
}`
    },
    productInfo: {
      name: "Product Information",
      template: `{
  "id": "prod-001",
  "name": "Smartphone X",
  "description": "Latest model with advanced features",
  "price": 799.99,
  "currency": "USD",
  "category": "Electronics",
  "inStock": true,
  "specifications": {
    "brand": "TechCo",
    "model": "X-2000",
    "color": "Midnight Blue",
    "storage": "128GB",
    "screenSize": "6.5 inches"
  },
  "images": [
    "https://example.com/images/smartphone-x-front.jpg",
    "https://example.com/images/smartphone-x-back.jpg"
  ],
  "reviews": [
    {
      "userId": 101,
      "rating": 5,
      "comment": "Great phone, love the features!"
    },
    {
      "userId": 102,
      "rating": 4,
      "comment": "Good value for money"
    }
  ]
}`
    },
    apiResponse: {
      name: "API Response",
      template: `{
  "status": "success",
  "code": 200,
  "data": {
    "users": [
      {
        "id": 1,
        "name": "Alice Johnson",
        "email": "alice@example.com"
      },
      {
        "id": 2,
        "name": "Bob Smith",
        "email": "bob@example.com"
      }
    ],
    "totalCount": 2,
    "page": 1,
    "perPage": 10
  },
  "message": "Users retrieved successfully"
}`
    }
  };

  const [selectedTemplate, setSelectedTemplate] = useState('userProfile');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(templates[selectedTemplate].template);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
      <div className="container mx-auto px-4 py-8">
        <Helmet>
  <title>{seo.title}</title>
  <meta name="description" content={seo.description} />
  {seo.keywords && <meta name="keywords" content={seo.keywords} />}
</Helmet>

        <h1 className="text-3xl font-bold mb-6">JSON Templates</h1>

        <div className="mb-4">
          <label htmlFor="template-select" className="block mb-2">Select a template:</label>
          <select
              id="template-select"
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
              className="w-full p-2 border rounded"
          >
            {Object.entries(templates).map(([key, { name }]) => (
                <option key={key} value={key}>{name}</option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">{templates[selectedTemplate].name}</h2>
          <pre className="bg-gray-100 p-4 rounded-md overflow-auto">
                    {templates[selectedTemplate].template}
                </pre>
        </div>

        <button
            onClick={handleCopy}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {copied ? 'Copied!' : 'Copy to Clipboard'}
        </button>
      </div>
  );
};

export default JsonTemplates;
